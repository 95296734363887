%button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 100%;
    height: 60px;
    border: 0;
    border-radius: 24px;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Manrope';
}
.yellow {
    @extend %button;
    color: #589D00;
    background-color: #F8F956;
    &:hover{
        color: #0D9D00;
        background-color: #FDFF1D;
        box-shadow: 0px 0px 10.9px 0px #FCFE2AAD;
    }
    &:active{
        color: #4B9D0A;
        background-color: #E5E621;
    }
}
.red {
    @extend %button;
    color: #FDD6CE;
    background-color: #EC495C;
    &:hover{
        color: #FDDFCE;
        background-color: #FD3B51;
        box-shadow: 0px 0px 10.9px 0px #FF4359BF;
    }
    &:active{
        color: #F6BAAD;
        background-color: #E23D50;
    }
}
.gray{
    @extend %button;
    color: #FFFFFF;
    background-color: #D0D1D8;
    &:hover{
        background-color: #C7C9D7;
        box-shadow: 0px 0px 10.9px 0px #C7C9D7A3;
    }
    &:active{
        background-color: #BEC0CD;
    }
}
.outline {
    @extend %button;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(242, 241, 243, 1);
    border: 0;
    border-radius: 24px;
}

.disabled{
    @extend %button;
    color: #FFFFFF;
    background-color: #DDDDDD;
    cursor: default;
}

.link {
    @extend %button;
    color: #868688;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}