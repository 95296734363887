@import "./reset.scss";

@font-face {
    font-family: 'IBMPlexSans';
    src: url(/fonts/IBMPlexSans-SemiBold.ttf);
}

@font-face {
    font-family: 'Manrope';
    src: url('/fonts/Manrope-SemiBold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('/fonts/Manrope-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

.app{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #CECDD5;
    height: 100%;
    min-height: 100vh;
}
.app_pc{
    align-items: center;
}

.loaderBlock{
    padding-bottom: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}