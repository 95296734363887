*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Manrope', 'Inter', 'Helvetica', 'Arial', sans-serif;
}

html, body{
    min-height: 100vh;

}

input, 
button, 
textarea,
select {
    margin: 0;
    font: inherit;
}

button, a {
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

button:focus, a:focus {
    outline: none;
}