%button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 100%;
  height: 60px;
  border: 0;
  border-radius: 24px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Manrope';
}

.wrapper {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 10%;
  .up {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
  }

  .content {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      margin-top: 10px;
      font-size: 28px;
      font-weight: 600;
      width: 65%;
      text-align: center;
    }

    span {
      font-size: 15px;
      color: #868688;
      margin-top: 10px;
      width: 65%;
      text-align: center;
    }
    
    .yellow {
      margin-top: 5%;
      @extend %button;
      text-decoration: none;
      color: #589D00;
      background-color: #F8F956;
      &:hover{
          color: #0D9D00;
          background-color: #FDFF1D;
          box-shadow: 0px 0px 10.9px 0px #FCFE2AAD;
      }
      &:active{
          color: #4B9D0A;
          background-color: #E5E621;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 30px;
  }
}
